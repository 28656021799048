<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base">
      <vx-card>
        <div class="vx-row">
          <div class="vx-col w-full">
            <vs-button v-if="userHasPermission('apps_integrations_edit')" @click="addPriceRule" class="mr-4 sm:mb-0 mb-2"
              >Add</vs-button
            >
            <vs-input
              class="
                float-right
                sm:mr-4
                mr-0
                sm:w-auto
                w-full
                sm:order-normal
                order-3
                sm:mt-0
                mt-2
              "
              v-model="searchQuery"
              @input="updateSearchQuery"
              placeholder="Search..."
            />
          </div>
        </div>

        <ag-grid-vue
          ref="agGridTable"
          :components="cellRendererComponents"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="priceRulesData"
          :gridOptions="gridOptions"
          rowSelection="single"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          @selection-changed="onSelectionChanged"
          @grid-size-changed="onGridSizeChanged"
          :context="context"
        >
        </ag-grid-vue>
        <vs-pagination :total="totalPages" :max="5" v-model="currentPage" />
      </vx-card>

      <vs-prompt
        title="Delete PriceRule"
        accept-text="Add"
        @cancel="closeDeletePriceRuleDialog"
        @close="closeDeletePriceRuleDialog"
        @accept="deletePriceRule"
        :active.sync="showDeletePriceRulePopup"
      >
        <div class="vx-row mt-2 mb-4">
          <div class="vx-col w-full">
            <label class="text-sm"
              >Assign Replacement PriceRule to Users:</label
            >
            <v-select
              v-model="selectedReplacementPriceRule"
              :options="replacementPriceRuleOptions"
            />
          </div>
        </div>
      </vs-prompt>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import CellRendererLink from "./cell-renderers/CellRendererLink.vue";
import CellRendererIsMemberCheckbox from "./cell-renderers/CellRendererIsMemberCheckbox.vue";
import CellRendererActions from "./cell-renderers/CellRendererActions.vue";
import TpNotificationMixin from "@/components/travio-pro/TpNotificationMixin.vue";
import priceRuleStore from "./priceRuleStore";
import helper from "./priceRuleHelper";
import Vue from "vue";
import TravioAccessControl from "../../components/travio-pro/TravioAccessControl.vue";
import { format } from 'date-fns'
import useTravioAccessControl from '@/components/travio-pro/useTravioAccessControl.js';

export default {
  components: {
    AgGridVue,
    CellRendererLink,
    CellRendererIsMemberCheckbox,
    CellRendererActions,
    TravioAccessControl,
  },
  mixins: [TpNotificationMixin],
  data() {
    return {
      dummyData: "",
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererLink,
        CellRendererActions,
      },
      searchQuery: "",
      showDeletePriceRulePopup: false,
      selectedReplacementPriceRule: {},
      context: null,
    };
  },
  setup () {
    const { userHasPermission } = useTravioAccessControl();
    return { userHasPermission }
  },
  computed: {
    priceRulesData() {
      return this.$store.state.priceRuleStore.priceRules;
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    preferedDateFormat() {      
      return this.$store.state.accountStore.generalSettings.dateFormat;
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
    replacementPriceRuleOptions() {
      return this.$_.map(this.$store.state.priceRuleStore.priceRules, (x) => {
        return { code: x.ruleId, label: x.name };
      });
    },
  },
  created() {
    if (!priceRuleStore.isRegistered) {
      this.$store.registerModule("priceRuleStore", priceRuleStore);
      priceRuleStore.isRegistered = true;
    }
    //#region fetch required data from api and store in state
    this.$vs.loading();
    this.$store
      .dispatch(
        "priceRuleStore/fetchPriceRules",
        this.$route.params.applicationId
      )
      .then(() => {
        // if user's general setting is not loaded yet, load it        
        let userDateFormat =
          this.$store.state.accountStore.generalSettings.dateFormat;
        if (!userDateFormat) {
          return this.$store.dispatch(
            "accountStore/loadAccount"
          );
        }
      })
      .catch((err) => console.error(err))
      .finally(() => this.$vs.loading.close());
    //#endregion
  },
  beforeMount() {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs = [
      { field: "ruleId", hide: true },
      {
        headerName: "Actions",
        width: 150,
        sortable: false,
        suppressSizeToFit: false,
        cellRendererFramework: Vue.extend(CellRendererActions),
      },
      {
        headerName: "Rule Id",
        width: 120,
        field: "ruleId",
        sortable: true,
        suppressSizeToFit: false,
      },
      {
        headerName: "Name",
        field: "ruleName",
        sortable: true,        
        suppressSizeToFit: false,
      },
      {
        headerName: "Priority",
        width: 120,
        field: "rulePriority",
        sortable: true,
        suppressSizeToFit: false,
      },
      {
        headerName: "Active",
        field: "ruleIsActive",
        sortable: true,
        valueFormatter: this.activeFormatter,
        suppressSizeToFit: false,
      },
      {
        headerName: "Member Pricing",
        sortable: false,
        suppressSizeToFit: false,
        cellRendererFramework: Vue.extend(CellRendererIsMemberCheckbox),
      },
       { 
        headerName: 'Created Date',
        field: 'ruleCreated',
        sortable: true,
        suppressSizeToFit: true,
        width: 180,
        valueFormatter: (param) => param.value && format(new Date(param.value) , this.activeUserInfo.dateTimeFormat)
      }
    ];

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      suppressMenu: true,
    };

    this.context = { componentParent: this }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },

  methods: {
    dateFormatter(date) {      
      return helper.dateFormatter.date(date.value,this.preferedDateFormat);
    },
    activeFormatter(active){      
      return active.value == true ? 'Active' : 'Inactive';
    },
    addPriceRule() {
      this.$router.push({
        name: "pricing-rule-addition",
        params: { applicationId: this.$route.params.applicationId },
      });
    },
    async onCloneRecord (id) {
      this.$vs.loading()
      try {
        const response = await this.$http.post(`api/pricerules/${this.$route.params.applicationId}/rules/clone`,{
          sourceRuleId: id
        })
        
        this.$_notifySuccess('Successfully cloned Price Rule redirecting to the new rule..');

        this.$router.push(
          `/applications/${this.$route.params.applicationId}/pricingRules/${response.data}/edit`
        )
        .catch((error) => {
          console.error(error);
        });
        
      }
      catch (error) {
        this.$_notifyFailure('Cloning of Price Rule failed. Reason: ' + response.data.error)
      }
      this.$vs.loading.close()  
    },
    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows();
    },
     onGridSizeChanged(params) {
      console.log(params.clientWidth);
      if (params.clientWidth > 500) {
        this.gridApi.sizeColumnsToFit();
      } else {
        this.columnDefs.forEach(x => {
          x.width = 300;
        });
        this.gridApi.setColumnDefs(this.columnDefs);
      }
    },
    onGridReady(params) {},
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    deletePriceRule() {},
    closeDeletePriceRuleDialog() {},
  },
};
</script>

<style>
</style>
