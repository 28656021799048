<template>
  <div :style="{ direction: $vs.rtl ? 'rtl' : 'ltr' }">
    <feather-icon
      title="View Details"
      icon="EyeIcon"
      svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
      @click="displayRecord"
    />
      <feather-icon
        v-if="userHasPermission('apps_integrations_edit')"
        title="Edit"
        icon="Edit3Icon"
        svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
        @click="editRecord"
      />
      <feather-icon 
        v-if="userHasPermission('apps_integrations_edit')" 
        title="Clone"
        icon="CopyIcon"
        svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" 
        @click="cloneRecord" />

      <feather-icon
        v-if="userHasPermission('apps_integrations_edit')"
        title="Delete"
        icon="TrashIcon"
        svgClasses="h-5 w-5 hover:text-danger cursor-pointer"
        @click="confirmDeleteRecord"
      />
    <vs-prompt
      title="Delete PriceRule"
      accept-text="Delete"
      @cancel="closeDeleteDialog"
      @close="closeDeleteDialog"
      @accept="deletePriceRule"
      :active.sync="showDeleteDialog"
    >
      <div class="vx-row mt-2 mb-4">
        <div class="vx-col">
          <span v-if="deleteAffectedUsersCount > 0"
            >You are about to delete a priceRule assigned to
            {{ deleteAffectedUsersCount }} user(s). Select a replacement
            priceRule for these user(s).</span
          >
          <span v-if="deleteAffectedUsersCount == 0"
            >Are you sure you want to delete '{{
              this.params.data.priceRuleName
            }}' priceRule?</span
          >
        </div>
        <div v-if="deleteAffectedUsersCount > 0" class="vx-col w-full mt-4">
          <label class="text-sm">Select Replacement PriceRule:</label>
          <v-select
            v-model="selectedReplacementPriceRule"
            :options="replacementPriceRuleOptions"
          />
        </div>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import TpNotificationMixin from "@/components/travio-pro/TpNotificationMixin.vue";
import vSelect from "vue-select";
import _ from "lodash";
import useTravioAccessControl from '@/components/travio-pro/useTravioAccessControl.js';

export default {
  name: "CellRendererActions",
  components: {
    vSelect,
  },
  mixins: [TpNotificationMixin],
  data() {
    return {
      showDeleteDialog: false,
      deleteAffectedUsersCount: 0,
      deleteHaveAffectedUsers: false,
      selectedReplacementPriceRule: null,
    };
  },
  setup () {
    const { userHasPermission } = useTravioAccessControl();
    return { userHasPermission }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    replacementPriceRuleOptions() {
      let filteredPriceRules = _.filter(
        this.$store.state.priceRuleStore.priceRules,
        (x) => x.priceRuleId != this.params.data.priceRuleId
      );
      return _.map(filteredPriceRules, (x) => {
        return { code: x.priceRuleId, label: x.priceRuleName };
      });
    },
  },
  methods: {
    editRecord() {
      // if (this.params.data.priceRuleType.toLowerCase() === 'system') {
      //   this.$_notifyFailure('System priceRules cannot be updated.')
      // } else {
      // }
      this.$router
        .push(
          `/applications/${this.$route.params.applicationId}/pricingRules/${this.params.data.ruleId}/edit`
        )
        .catch((error) => {
          console.error(error);
        });
    },
    displayRecord() {
      this.$router
        .push({
          name: "pricing-rule-view",
          params: {
            applicationId: this.$route.params.applicationId,
            pricingRuleId: this.params.data.ruleId,
          },
        })
        .catch((error) => {
          console.error(error);
        });
    },
    cloneRecord () {
      this.params.context.componentParent.onCloneRecord(this.params.data.ruleId) 
    },
    confirmDeleteRecord() {
      // if (this.params.data.priceRuleType.toLowerCase() === 'system') {
      //   this.$_notifyFailure('System priceRules cannot be deleted.')
      //   return;
      // }

      // this.$vs.loading()
      // this.$http.get(`api/companies/${this.activeUserInfo.companyId}/priceRules/${this.params.data.priceRuleId}/userCount`)
      //   .then(response => {
      //     this.showDeleteDialog = true
      //     this.deleteHaveAffectedUsers = response.data > 0
      //     this.deleteAffectedUsersCount = response.data
      //   })
      //   .catch(error => {
      //     this.$_notifyFailureByResponseData(error.response.data)
      //   })
      //   .finally(() => this.$vs.loading.close())
      this.showDeleteDialog = true;
    },
    closeDeleteDialog() {
      this.deleteHaveAffectedUsers = false;
      this.deleteAffectedUsersCount = 0;
      this.selectedReplacementPriceRule = null;
    },
    deletePriceRule() {
      // if (this.deleteAffectedUsersCount > 0 && !this.selectedReplacementPriceRule ) {
      //   this.$_notifyFailure('Unable to delete priceRule. Please select replacement priceRule for affected users.')
      //   return
      // }
      const payload = {
        applicationId: this.$route.params.applicationId,
        priceRuleId: this.params.data.ruleId,
      };
      this.$vs.loading();
      this.$store
        .dispatch("priceRuleStore/removePriceRule", payload)
        .then((response) => {
           return this.$store.dispatch(
            "priceRuleStore/fetchPriceRules",
            this.$route.params.applicationId
          ).then((res)=>{
            this.$_notifySuccess("Successfully deleted priceRule");
          });
        })
        .catch((error) => {
          this.$_notifyFailureByResponseData(error.response.data);
        })
        .finally(() => this.$vs.loading.close());
    },
  },
};
</script>
